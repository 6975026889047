export const Emitter_Keys = {
  SHOW_OPTION_FILTER: 'event.optionFilter:true',
  SHOW_SETTING_BOTTOMSHEET: 'event.settingBottomSheet:true',
  SHOW_BLOCKED_ACCOUNT: 'event.blockedAccount:true',
  SHOW_NEED_ACTIVATE: 'event.needActivate:true',
  SHOW_INCOMING_FEATURE: 'event.incomingFeature:true',
  SHOW_ACCOUNT_PREPARED: 'event.accountPrepared:true',
  SHOW_ACCOUNT_DELETED: 'event.accountDeleted:true',
  SHOW_CALL_SAPA_RAYA: 'event.callSapaRaya:true',
  SHOW_GLOBAL_ERROR: 'event.globalError:true',
  SHOW_TAMBAH_SALDO_BOTTOMSHEET: 'event.tambahSaldoBottomSheet:true',
  SHOW_PINDAH_SALDO_BOTTOMSHEET: 'event.pindahSaldoBottomSheet:true',
  SHOW_HAPUS_SAKU_BOTTOMSHEET: 'event.hapusSakuBottomSheet:true',
};
