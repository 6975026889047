import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QRISTransactionHistoryType } from 'types/qrisTransactionHistoryTypes';

export interface QrisTransactionHistoryState {
  data: QRISTransactionHistoryType;
}

const qrisTransactionHistorySlice = createSlice({
  name: 'qrisTransactionHistoryDetail',
  initialState: {
    data: {},
  } as QrisTransactionHistoryState,
  reducers: {
    setQrisTransactionHistory: (state, action: PayloadAction<QRISTransactionHistoryType>) => {
      state.data = {
        id: action.payload.id,
        qrisBusinessId: action.payload.qrisBusinessId,
        qrisType: action.payload.qrisType,
        amount: action.payload.amount,
        createDate: action.payload.createDate,
        updateDate: action.payload.updateDate,
        paidDate: action.payload.paidDate,
        paymentStatus: action.payload.paymentStatus,
        payoutStatus: action.payload.payoutStatus,
        businessName: action.payload.businessName,
        senderName: action.payload.senderName,
        acquirerName: action.payload.acquirerName,
        bankName: action.payload.bankName,
        transactionId: action.payload.transactionId,
        note: action.payload.note,
        mpan: action.payload.mpan,
        terminalId: action.payload.terminalId,
        customerPan: action.payload.customerPan,
        reffId: action.payload.reffId,
        information: action.payload.information,
        mdr: action.payload.mdr,
        tipsValue: action.payload.tipsValue,
        total: action.payload.total,
      };
    },
    clearQrisTransactionHistory: (state) => {
      state.data = {
        id: '',
        qrisBusinessId: '',
        qrisType: '',
        amount: '',
        createDate: '',
        updateDate: '',
        paidDate: '',
        paymentStatus: '',
        payoutStatus: '',
        businessName: '',
        senderName: '',
        acquirerName: '',
        bankName: '',
        transactionId: '',
        note: '',
        mpan: '',
        terminalId: '',
        customerPan: '',
        reffId: '',
        information: '',
        mdr: '',
        tipsValue: '',
        total: '',
      };
    },
  },
});

export const { setQrisTransactionHistory, clearQrisTransactionHistory } =
  qrisTransactionHistorySlice.actions;
export default qrisTransactionHistorySlice.reducer;
