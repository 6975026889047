import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Common from 'data/constants/common';
import { ActiveFilterType } from 'types/activeFilterTypes';
import { Utils } from 'utils/utils';

export interface IHistoryLotteryFilterState {
  historyLotteryFilter: ActiveFilterType | null;
}

const currentDate = new Date();

const initialState = {
  historyLotteryFilter: {
    isActiveFilter: false,
    dateFilter: {
      months: Common.months[currentDate.getMonth()],
      years: `${currentDate.getFullYear()}`,
    },
    dateRangeFilter: {
      startDate: Utils.getFirstDayHistoryOfMonth(currentDate.getFullYear(), currentDate.getMonth()),
      endDate: Utils.getLastDayHistoryOfMonth(currentDate.getFullYear(), currentDate.getMonth()),
    },
    optionFilter: Common.FILTER_OPTIONS[0],
  },
} as IHistoryLotteryFilterState;

const historyLotteryFilterSlice = createSlice({
  name: 'historyLotteryFilter',
  initialState,
  reducers: {
    setHistoryLotteryFilter: (state, action: PayloadAction<ActiveFilterType>) => {
      state.historyLotteryFilter = {
        ...state.historyLotteryFilter,
        ...action.payload,
      };
    },
    removeLotteryFilter: (state) => {
      state.historyLotteryFilter = initialState.historyLotteryFilter;
    },
  },
});

export const { setHistoryLotteryFilter, removeLotteryFilter } = historyLotteryFilterSlice.actions;
export default historyLotteryFilterSlice.reducer;
