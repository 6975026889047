import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SakuType from 'types/sakuTypes';
import { TransactionType } from '../../types/transactionTypes';

export interface IMassTransferState {
  data: TransactionType[];
  sakuChoosed: SakuType | null;
}

const massTransferSlice = createSlice({
  name: 'massTransfer',
  initialState: {
    data: [],
    sakuChoosed: {},
  } as IMassTransferState,
  reducers: {
    addSakuChoosed: (state, action: PayloadAction<SakuType>) => {
      state.sakuChoosed = {
        ...state.sakuChoosed,
        ...action.payload,
      };
    },

    clearSakuChoosed: (state) => {
      state.sakuChoosed = {};
    },

    addMassTransfer: (state, action: PayloadAction<TransactionType>) => {
      state.data = [action.payload, ...state.data];
    },

    removeMassTransfer: (state, action: PayloadAction<number>) => {
      state.data.splice(action.payload, 1);
    },

    clearMassTransfer: (state) => {
      state.data = [];
    },

    updateMassTransfer: (
      state,
      action: PayloadAction<{ index: number; properties: Partial<TransactionType> }>
    ) => {
      const { index, properties } = action.payload;
      if (index >= 0 && index < state.data.length) {
        state.data[index] = { ...state.data[index], ...properties };
      }
    },
  },
});

export const {
  addSakuChoosed,
  clearSakuChoosed,
  addMassTransfer,
  removeMassTransfer,
  clearMassTransfer,
  updateMassTransfer,
} = massTransferSlice.actions;
export default massTransferSlice.reducer;
