import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UseBankListData } from '../../hooks/useBankList';
import { TransactionType } from '../../types/transactionTypes';
import { ScheduleType } from 'types/scheduleTypes';

export interface SavedCheckDestinationResult {
  name?: string;
  number?: string;
  code?: string;
  transferMethod?: string;
  trxType?: string;
  isFavorite?: boolean;
  biFastStatus?: string;
}

export interface ITransferState {
  bankDestination: UseBankListData | null;
  data: TransactionType | null;
  savedCheckDestinationResult: SavedCheckDestinationResult;
  scheduleTransfer: ScheduleType | null;
}

const transferSlice = createSlice({
  name: 'transfer',
  initialState: {
    bankDestination: {},
    data: {},
    savedCheckDestinationResult: {},
    scheduleTransfer: {
      isSchedule: false,
      isEdit: false,
      isTransfer: false,
      day: '',
      date: 0,
      accountType: 'business',
    },
  } as ITransferState,
  reducers: {
    addTransfer: (state, action: PayloadAction<TransactionType>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    addSchedule: (state, action: PayloadAction<ScheduleType>) => {
      state.scheduleTransfer = {
        ...state.scheduleTransfer,
        ...action.payload,
      };
    },
    addBankDestination: (state, action: PayloadAction<UseBankListData>) => {
      state.bankDestination = action.payload;
    },
    clearTransfer: (state) => {
      state.data = {};
      state.bankDestination = {};
      state.savedCheckDestinationResult = {};
      state.scheduleTransfer = {
        isSchedule: false,
        isEdit: false,
        isTransfer: false,
        day: '',
        date: 0,
        accountType: 'business',
      };
    },
    clearScheduleTransfer: (state) => {
      state.scheduleTransfer = {
        isSchedule: false,
        isEdit: false,
        isTransfer: false,
        day: '',
        date: 0,
        accountType: 'business',
      };
    },
    addSavedCheckDestinationResult: (state, action: PayloadAction<SavedCheckDestinationResult>) => {
      state.savedCheckDestinationResult = {
        ...state.savedCheckDestinationResult,
        ...action.payload,
      };
    },
    clearSavedCheckDestinationResult: (state) => {
      state.savedCheckDestinationResult = {};
    },
  },
});

export const {
  addTransfer,
  addSchedule,
  addBankDestination,
  clearTransfer,
  addSavedCheckDestinationResult,
  clearSavedCheckDestinationResult,
  clearScheduleTransfer,
} = transferSlice.actions;
export default transferSlice.reducer;
