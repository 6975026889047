const Common = {
  miminumDeposit: 50000,
  months: [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
  errorGenericTitle: 'Waduh! Lagi ada gangguan nih..',
  errorGenericDescription: 'Tenang, tim kami sedang perbaikin. Kembali lagi nanti, ya!',
  maxSakuBisnis: 5,
  genericErrorOfMassTransferFailed:
    'Tenang, saldomu akan dikembalikan otomatis bila telah terpotong. Hubungi Sapa Raya jika dalam 1x24 jam saldo tidak kembali.',
  bankRayaCopyWord: 'Bank Raya Indonesia',
  bankRayaCode: '494',
  FILTER_OPTIONS: ['Bulan ini', 'Pilih bulan'],
  lostConnectionTitle: 'Yah, koneksinya terputus',
  lostConnectionDescription: 'Cek koneksi Wifi atau kuota internet kamu, lalu coba lagi.',
  eodTitle: 'Transaksi belum bisa dilakukan',
  eodDescription: 'Transaksi sedang dalam pemeliharaan. Silakan coba lagi nanti, ya!',
};

export default Common;
