import * as Sentry from '@sentry/nextjs';

export default class SentryError extends Error {
  constructor({ message, name, cause }: { message: string; name: string; cause: any }) {
    super();
    this.name = name;
    this.message = message;
    this.cause = cause; // set the cause to maintain linkage to the original error
  }

  captureException() {
    Sentry.captureException(this);
  }
}
