import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const imgResultSlice = createSlice({
name: 'imgResult',
initialState: {
  imgSrc: '',
},
reducers: {
  setImgSrc: (state, action: PayloadAction<string>) => {
    state.imgSrc = action.payload;
  },

  clearImgSrc: (state) => {
    state.imgSrc = '';
  }
},
});

export const { setImgSrc, clearImgSrc } =
imgResultSlice.actions;
export default imgResultSlice.reducer;