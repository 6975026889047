const Images = {
  loadingBuffering: '/assets/images/loading-buffering.gif',
  loadingSpinner: '/assets/images/loading-spinner.gif',
  loadingSpinnerRaya: '/assets/images/loading-spinner-raya.gif',

  // Saku Bisnis
  vectorBackground: '/assets/images/vector-background.svg',
  vectorBackgroundInfo: '/assets/images/vector-background-info.svg',
  vectorBackgroundIntro: '/assets/images/vector-background-intro-masstransfer.svg',
  vectorBackgroundDashboard: '/assets/images/vector-background-dashboard.svg',
  vectorBackgroundBlueDashboard: '/assets/images/vector-background-blue-dashboard.svg',
  vectorBackgroundDotted: '/assets/images/vector-background-dotted.svg',
  vectorAccentSaku: '/assets/images/vector-accent-saku.svg',
  headerBackground: '/assets/images/header-bg.svg',
  bannerBackground: '/assets/images/banner-background.svg',
  genericError: '/assets/images/generic-error.svg',
  pinLockTemp: '/assets/images/pin_locked_temp.svg',
  pinLockPermanent: '/assets/images/pin_locked_permanent.svg',
  emptyFavorite: '/assets/images/empty-favorite.svg',
  emptyTransaction: '/assets/images/empty-transaction.svg',
  patternRaya: '/assets/images/pattern-raya.png',
  contactCenter: '/assets/images/contact-center.png',
  loaderRaya: '/assets/images/loader_raya.gif',
  fullFavorite: '/assets/images/sheet-full-favorite.svg',
  errorEod: '/assets/images/error-eod.svg',
  failedTransfer: '/assets/images/failed-transfer.svg',
  rayaLogoColor: '/assets/images/raya-logo-color.svg',
  rayaLogoWhite: '/assets/images/raya-logo-white.svg',
  invitationSakuAnak: '/assets/images/invitation-saku-anak.svg',
  verifyDop: '/assets/images/ilustration-verify-dop.svg',
  photoRejected: '/assets/images/illustration-photo-rejected.svg',
  preparedSakuAnak: '/assets/images/prepared-saku-anak.svg',
  calender: '/assets/images/calendar-icon.svg',

  // Saku Anak
  headerBackgroundYouth: '/assets/saku_anak/images/header-background.svg',
  akunBackgroundYouth: '/assets/saku_anak/images/akun-background.svg',
  bannerQris: '/assets/saku_anak/images/banner-qris.svg',
  textQris: '/assets/saku_anak/images/text-qris.svg',
  illustrationUpcoming: '/assets/saku_anak/images/illustration-upcoming.png',
  upcomingQris: '/assets/saku_anak/images/upcoming-qris.svg',
  continueActivation: '/assets/saku_anak/images/continue-activation-saku-youth.svg',
  childBag: '/assets/images/child-bag.svg',
  deleteSakuAnakIllustration: '/assets/saku_anak/images/delete-acc-illustration.svg',
  preparedBS: '/assets/saku_anak/images/prepared-bs.svg',

  // Saku Bisnis QRIS
  emptyQris: '/assets/images/empty-qris.svg',
  limitQris: '/assets/images/limit-qris.svg',
  uncompleteQris: '/assets/images/uncomplete-qris.svg',
  approvedQris: '/assets/images/approved-qris.svg',
  detailQris: '/assets/images/detail-qris.svg',
  detailQrisBackground: '/assets/images/detail-qris-background.svg',
  qrisWording: '/assets/images/qris-wording.svg',
  qrisGpn: '/assets/images/gpn-icon.svg',
  qrisBadge: '/assets/images/qris-badge.svg',
  qrisPolygon2: '/assets/images/polygon-2.svg',
  qrisPolygon3: '/assets/images/polygon-3.svg',

  // NOTE: Delete if data already dynamic
  rightOfflinePhotoExample: '/assets/images/right-offline-photo-example.png',
  rightOnlinePhotoExample: '/assets/images/right-online-photo-example.png',
  wrongOfflinePhotoExample: '/assets/images/wrong-offline-photo-example.png',
  wrongOnlinePhotoExample: '/assets/images/wrong-online-photo-example.png',
  offlinePhotoExample: '/assets/images/offline-store-example.png',
  offlinePhotoExampleBlur: '/assets/images/offline-store-example-blur.png',
  offlinePhotoExampleCut: '/assets/images/offline-store-example-cut.png',

  // Raya Lottery
  vgnza: '/assets/images/vgnza.png',
  swam: '/assets/images/swam.png',
  tipsLamp: '/assets/images/tips-lamp.png',
  carPrize: '/assets/images/car-prize.png',
  qrisIcons: '/assets/images/qris-point.svg',
  bannerHowToGetPoint: '/assets/images/banner-how-to-get-point.png',
  imgGadget: '/assets/images/img-gadget.svg',
  imgMotor: '/assets/images/img-motor.svg',
  hadiahPestaRaya: '/assets/images/hadiah-pesta-raya.png',
};

export default Images;
